.destinationsMainContainer {
    padding: 3.0625vw;
}

.destinationsHeadingdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8125vw;
}

.destinationsHeadingdiv>img {
    width: 2.0625vw;
    height: 1.9375vw;
    margin-top: -0.625vw
}

.destinationsHeadingdiv>div {
    font-size: 2vw;
    font-weight: 800;
    font-family: 'interBold'
}

.VideoSectionOverlay {
    background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.251) 25.74%, rgba(0, 0, 0, 0.412) 98.75%);
    height: 11.875vw;
    width: 100%;
    padding: 2.40625vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.VideoSectionMainBgImg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.destinationsGridView {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.3125vw;
    justify-items: center;
    padding-top: 2.375vw;
}

.destinationsItemDiv {
    width: 100%;
    height: 16vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 0.274375vw;
    cursor: pointer;
}

.destinationsItemOverlay {
    height: 10vw;
    width: 100%;
    background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.25) 20.59%, rgba(0, 0, 0, 0.5) 98.75%);
    padding: 1.375vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625vw;
    flex-direction: column;
    border-radius: 0.274375vw;
}

.destinationsCountryName {
    font-size: 1.84375vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #fff
}

.destinationsViewBtn {
    height: 1.8125vw;
    width: 6.25vw;
    border-radius: 0.1875vw;
    font-size: 0.84375vw;
    font-weight: 700;
    font-family: 'interBold';
    color: #000;
    background-color: #FFC432;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.mostPopularMainContainer {
    padding: 3.0625vw;
    padding-bottom: 6.25vw;
}

.mostPopularHeadingdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mostPopularHeadingdiv>div {
    font-size: 2vw;
    font-weight: 800;
    font-family: 'interBold'
}

.mostPopularGridView {
    width: 60vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.3125vw;
    justify-items: center;
    padding-top: 2.375vw;
}

.mostPopularGridViewLanding {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 2.3125vw;
    justify-content: center;
    align-items: center;
    padding-top: 2.375vw;
}

.mostPopularItemDiv {
    width: 100%;
    height: 12.5625vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 0.125vw;
    cursor: pointer;
}

.mostPopularItemOverlay {
    height: 6.9375vw;
    width: 100%;
    background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.25) 20.59%, rgba(0, 0, 0, 0.5) 98.75%);
    padding: 1.375vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625vw;
    flex-direction: column;
    border-radius: 0.125vw;
}

.mostPopularItemFooterDiv {
    padding: 0.4375vw 0vw;
    border-bottom: 0.3125vw solid #FAC809;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.mostPopularFlagDiv {
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 50%;
    box-shadow: 0vw 0.125vw 0.125vw 0vw #00000040;
    position: absolute;
    margin-left: 13.625vw;
    margin-top: 1vw
}

.mostPopularFlagDiv>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover
}

.mostPopularItemName {
    font-size: 1vw;
    font-weight: 700;
    font-family: 'interBold'
}

.mostPopularCountryAndDaysDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625vw;
    font-size: 0.875vw;
    font-family: 'inter'
}

.mostPopularCountryDaysDivider {
    border-left: 0.1875vw solid #FAC809;
    height: 1.125vw;
    background: #FAC809
}

.mostPopularJeepIcon {
    width: 1.875vw;
    height: 1.875vw;
}

.indieGuideMainContainer {
    background-color: #FFC432D9;
}

.indieGuideBgImg {
    width: 100%;
    height: 100%;
    padding: 2.5vw 2.5vw 3.625vw 2.5vw;
    background-image: url('../../assets/indieGuideBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.indieGuideMainHead {
    font-size: 2.125vw;
    font-weight: 800;
    font-family: 'interBold';
    text-align: center
}

.indieGuideInnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.3125vw;
    margin-top: 2.625vw;
}

.indieGuideInnerItemDiv {
    font-size: 1.125vw;
    font-weight: 700;
    font-family: 'interBold';
    text-align: center;
}

.indieGuideInnerItemDiv>img {
    width: 10.5vw;
    height: 18.25vw;
}

.HeadlineMainContainer {
    padding: 2.5vw;
    background: #977E6A33;
}

.HeadlineMainHeadDiv {
    color: #977E6A;
    font-size: 2.125vw;
    font-weight: 700;
    text-align: center;
    font-family: 'interBold'
}

.HeadlineItemDiv {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.375vw;
    justify-items: center;
    padding-top: 2.375vw;
}

.HeadlineItemDiv>div {
    width: 100%;
    height: 25.25vw;
    background: #FFFFFF;
    box-shadow: 0.125vw 0.25vw 1.5vw 0vw #00000014;
    padding: 2vw;
    border-radius: 1.0625vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.0625vw;
    flex-direction: column;
    font-size: 1vw;
    font-family: 'inter';
}

.HeadlineItemDiv>div>img {
    width: 6.5625vw;
    height: 1.3125vw;
}

.HeadlineItemPersonName {
    font-size: 1.125vw;
    font-weight: 600;
}

.HeadlineItemGoogle {
    font-size: 0.75vw;
    font-weight: 400;
    color: #4285F4
}

.travelWithIndieMainContainer {
    padding: 3.5vw;
    background-color: #977E6A0D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.travelWithIndieMainHead {
    color: #3E2A1A;
    text-align: center;
    font-family: 'interBold';
    font-size: 2.375vw;
}

.travelWithIndieInnerContainer {
    width: 65vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2.9375vw;
    margin: 3.1875vw 0vw;
}

.travelWithIndieItemContainer {
    display: flex;
    flex-direction: column;
    gap: 2.375vw;
    width: 100%;
}

.travelWithIndieItemContainer>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.9375vw;
}

.travelWithIndieItemContainer>div>img {
    width: 4.125vw;
    height: 4.25vw;
}

.travelWithIndieItemContainer>div>div {
    display: flex;
    flex-direction: column;
}

.travelWithIndieItemName {
    font-size: 1.5vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A
}

.travelWithIndieItemDes {
    font-size: 1.125vw;
    font-weight: 500;
    font-family: 'inter';
    color: #3E2A1A
}

.careMainContainer {
    width: 100%;
    background-image: url("../../assets/careBgImg.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: 100% 50% */
    background-position: center
}

.careOverlayDiv {
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(62, 42, 26, 0) 50%, rgba(62, 42, 26, 0.35) 75%, rgba(62, 42, 26, 0.45) 95%);
}

.careInnerContainer {
    width: 80vw;
    margin: 0 auto;
    padding: 7.5vw 0vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.careInnerContainer>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3.4375vw;
}

.careCommittedBtn {
    background-color: #FFC432;
    width: 20vw;
    border-radius: 3.25vw;
    height: 3.75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-weight: 700;
    font-family: 'interBold';
    cursor: pointer;
}

.careSecondItemDiv {
    background: #FFFFFFD9;
    height: 100%;
    width: 100%;
    padding: 3.125vw;
}

.careSecondItemAllImgs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5vw
}

.careSecondItemAllImgs>img {
    height: 4.5vw;
    width: 4.5vw;
}

.careSecondItemDes {
    text-align: center;
    font-size: 1.0625vw;
    font-weight: 600;
    font-family: 'interSemiBold'
}

.localExpertMainContainer {
    width: 100%;
    padding: 3.9375vw 0vw;
}

.localExpertInnerContainer {
    width: 80vw;
    margin: 0 auto;
}

.localExpertMainHead {
    font-size: 2.375vw;
    font-weight: 700;
    color: #3E2A1A;
    font-family: 'interSemiBold';
    text-align: center
}

.localExpertShortDes {
    font-size: 1.0625vw;
    font-weight: 400;
    color: #3E2A1A;
    font-family: 'inter';
    text-align: center;
    margin-top: 0.3125vw
}

.localExpertBtn {
    width: 20vw;
    margin: 0 auto;
    font-size: 1.25vw;
    font-weight: 700;
    font-family: 'interBold';
    color: #fff;
    height: 3.75vw;
    background-color: #422E18;
    border-radius: 3.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 1.875vw;
}

.shareYourDetailsMainContainer {
    padding: 3vw;
    background-color: #FBD035;
}

.shareYourDetailsInnerContainer {
    width: 80vw;
    margin: 0 auto;
}

.shareYourDetailsMainHead {
    font-size: 1.5vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A;
    text-align: center;
    margin-bottom: 2.5vw
}

.shareYourDetailsItemDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.125vw;
    margin-bottom: 2.5vw;
}

.shareYourDetailsItemDiv>input {
    background-color: white;
    border: none;
    outline: none;
    box-shadow: 0.25vw 0.25vw 0.5vw 0vw #00000014;
    padding: 0.5vw;
    width: 13.125vw;
    height: 2.6875vw;
    border-radius: 0.1875vw;
    color: #3E2A1A;
    font-size: 0.875vw;
    font-weight: 500;
    font-family: 'inter';
}

.shareYourDetailsItemDiv>input::placeholder {
    color: #3E2A1A;
}

.shareYourDetailsSelectDiv {
    background-color: white;
    border: none;
    outline: none;
    box-shadow: 0.25vw 0.25vw 0.5vw 0vw #00000014;
    padding: 0.5vw;
    width: 13.125vw;
    height: 2.6875vw;
    border-radius: 0.1875vw;
    color: #3E2A1A;
    font-size: 0.875vw;
    font-weight: 500;
    font-family: 'inter';
}

.shareYourDetailsSendBtn {
    width: 11.875vw;
    height: 2.6875vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #422E18;
    border-radius: 2.6875vw;
    font-size: 1.25vw;
    font-weight: 700;
    font-family: 'interBold';
    color: #fff;
    cursor: pointer;
}

.shareYourDetailsLastDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.125vw;
}
.shareYourDetailsCheckDiv{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:0.6875vw;
    padding-right:1.25vw
}

.shareYourDetailsCheckDiv>input{
    width:1.25vw;
    height:1.25vw;
}
.shareYourDetailsCheckDiv>span{
 color:#3E2A1A;
 font-family:'interSemiBold';
 font-size:0.75vw;
 font-weight:600;
}
.shareYourDetailsSocialIconDiv{
    display:flex;
    justify-content:center;
    align-items:center;
    gap:0.6875vw;
}
.shareYourDetailsSocialIconDiv>img{
    width:2.6875vw;
    height:2.6875vw;
}

select {
    display: block;
    width: 100%;
    font-size: 1em;
    padding: 0.8rem 0.5rem;
    border: 0.0625vw solid #333;
    font-family: inherit;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

@media only screen and (max-width: 768px) {
    .VideoSectionOverlay {
        background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.251) 25.74%, rgba(0, 0, 0, 0.412) 98.75%);
        height: 14vw;
        width: 100%;
        padding: 4.40625vw;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .destinationsHeadingdiv {
        gap: 1.5vw;
    }

    .destinationsHeadingdiv>img {
        width: 5.5vw;
        height: 5.5vw;
    }

    .destinationsHeadingdiv>div {
        font-size: 5vw;
    }

    .destinationsGridView {
        width: 90vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5vw;
        justify-items: center;
        padding-top: 3vw;
    }

    .destinationsItemDiv {
        width: 100%;
        height: 38vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-radius: 1vw;
        cursor: pointer;
    }

    .destinationsItemOverlay {
        height: 26vw;
        width: 100%;
        background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.25) 20.59%, rgba(0, 0, 0, 0.5) 98.75%);
        padding: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5vw;
        flex-direction: column;
        border-radius: 1vw;
    }

    .destinationsCountryName {
        font-size: 5.2vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #fff
    }

    .destinationsViewBtn {
        height: 6.8vw;
        width: 17.5vw;
        border-radius: 0.5vw;
        font-size: 3.2vw;
        font-weight: 700;
        font-family: 'interBold';
        color: #000;
        background-color: #FFC432;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .mostPopularHeadingdiv>div {
        font-size: 5vw;
        margin-top: 5vw;
    }

    .mostPopularGridView {
        width: 90vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5vw;
        justify-items: center;
        padding-top: 3vw;
    }

    .mostPopularItemDiv {
        width: 100%;
        height: 28vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-radius: 1vw 1vw 0vw 0vw;
        cursor: pointer;
    }

    .mostPopularItemOverlay {
        height: 15vw;
        width: 100%;
        background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.25) 20.59%, rgba(0, 0, 0, 0.5) 98.75%);
        padding: 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5vw;
        flex-direction: column;
        /* border-radius: 1vw; */
    }

    .mostPopularItemFooterDiv {
        padding: 0.4vw 0vw;
        border-bottom: 0.6vw solid #FAC809;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .mostPopularFlagDiv {
        height: 8.5vw;
        width: 8.5vw;
        border-radius: 50%;
        box-shadow: 0vw 0.125vw 0.125vw 0vw #00000040;
        position: absolute;
        margin-left: 33vw;
        margin-top: 2vw
    }

    .mostPopularItemName {
        font-size: 3.2vw;
        font-weight: 700;
        font-family: 'interBold'
    }

    .mostPopularCountryAndDaysDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1vw;
        font-size: 2.7vw;
        font-family: 'inter'
    }

    .mostPopularCountryDaysDivider {
        border-left: 0.4vw solid #FAC809;
        height: 2.7vw;
        background: #FAC809
    }

    .mostPopularJeepIcon {
        width: 5.5vw;
        height: 5.5vw;
    }


    .indieGuideMainContainer {
        background-color: #FFC432D9;
        margin-top: 6vw;
    }

    .indieGuideBgImg {
        padding: 8vw 5vw 8vw 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4vw;
    }

    .indieGuideMainHead {
        font-size: 5.5vw;
    }

    .indieGuideInnerDiv {
        width: 90vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        gap: 5vw 0vw;
        margin-top: 3vw;
    }

    .indieGuideInnerItemDiv {
        font-size: 4vw;
        font-weight: 700;
        font-family: 'interBold';
        text-align: center;
    }

    .indieGuideInnerItemDiv>img {
        width: 30.5vw;
        height: 54.25vw;
    }

    .HeadlineMainContainer {
        padding: 5vw;
        background: #977E6A33;
    }

    .HeadlineMainHeadDiv {
        color: #977E6A;
        font-size: 5vw;
        font-weight: 700;
        text-align: center;
        font-family: 'interBold';
        margin-bottom: 2vw;
    }

    .HeadlineItemDiv {
        width: 85vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 4vw;
        justify-items: center;
        padding-top: 2.375vw;
    }

    .HeadlineItemDiv>div {
        width: 100%;
        height: auto;
        background: #FFFFFF;
        box-shadow: 0.125vw 0.25vw 1.5vw 0vw #00000014;
        padding: 5vw;
        border-radius: 2vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2vw;
        flex-direction: column;
        font-size: 3vw;
        font-family: 'inter';
        text-align: center;
    }

    .HeadlineItemDiv>div>img {
        width: 12.5vw;
        height: 3vw;
    }

    .HeadlineItemPersonName {
        font-size: 3.8vw;
        font-weight: 600;
    }

    .HeadlineItemGoogle {
        font-size: 3vw;
        font-weight: 400;
        color: #4285F4
    }

    .travelWithIndieMainContainer {
        padding: 5vw;
    }

    .travelWithIndieMainHead {
        font-size: 5vw;
    }

    .travelWithIndieInnerContainer {
        width: 90vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2vw;
        margin: 6vw 0vw;
    }

    .travelWithIndieItemContainer {
        display: flex;
        flex-direction: column;
        gap: 5vw;
        width: 100%;
    }

    .travelWithIndieItemContainer>div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2vw;
    }

    .travelWithIndieItemContainer>div>img {
        width: 12vw;
        height: 12vw;
    }

    .travelWithIndieItemContainer>div>div {
        display: flex;
        flex-direction: column;
    }

    .travelWithIndieItemName {
        font-size: 3.2vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #3E2A1A
    }

    .travelWithIndieItemDes {
        font-size: 2.3vw;
        font-weight: 500;
        font-family: 'inter';
        color: #3E2A1A
    }

    .careMainContainer {
        width: 100%;
        background-image: url("../../assets/careBgImg.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center
    }

    .careInnerContainer {
        width: 85vw;
        margin: 0 auto;
        padding: 5vw 0vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5vw;
    }

    .careInnerContainer>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4vw;
    }

    .careCommittedBtn {
        background-color: #FFC432;
        width: 45vw;
        border-radius: 8vw;
        height: 6.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5vw;
        font-weight: 700;
        font-family: 'interBold';
        cursor: pointer;
    }

    .careOverlayDiv {
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, rgba(62, 42, 26, 0) 0%, rgba(62, 42, 26, 0.35) 50%, rgba(62, 42, 26, 0.45) 95%);
    }

    .careSecondItemDiv {
        background: #FFFFFFD9;
        height: 100%;
        width: 100%;
        padding: 5vw 5vw 1vw 5vw;
    }

    .careSecondItemAllImgs>img {
        height: 10vw;
        width: 10vw;
    }

    .careSecondItemAllImgs {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .careSecondItemDes {
        text-align: center;
        font-size: 2.9vw;
        font-weight: 600;
        font-family: 'interSemiBold';
        margin-top: 3vw;
    }

    .localExpertMainContainer {
        width: 100%;
        padding: 5vw;
    }

    .localExpertInnerContainer {
        width: 85vw;
        margin: 0 auto;
    }

    .localExpertMainHead {
        font-size: 6vw;
        font-weight: 700;
        color: #3E2A1A;
        font-family: 'interSemiBold';
        text-align: center
    }

    .localExpertShortDes {
        font-size: 3.2vw;
        font-weight: 400;
        color: #3E2A1A;
        font-family: 'inter';
        text-align: center;
        margin-top: 1vw;
        margin-bottom: 1vw;
        line-height: 4.8vw;
    }

    .localExpertBtn {
        width: 40vw;
        margin: 0 auto;
        font-size: 2.5vw;
        font-weight: 700;
        font-family: 'interBold';
        color: #fff;
        height: 7vw;
        background-color: #422E18;
        border-radius: 4vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 2vw;
    }

    .shareYourDetailsMainContainer {
        padding: 5vw;
        background-color: #FBD035;
    }

    .shareYourDetailsInnerContainer {
        width: 85vw;
        margin: 0 auto;
    }

    .shareYourDetailsMainHead {
        font-size: 5vw;
        margin-bottom: 2.5vw
    }

    .shareYourDetailsItemDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4vw;
        margin-bottom: 2.5vw;
        width: 100%;
        margin-top: 4vw;
    }

    .shareYourDetailsItemDiv>input {
        background-color: white;
        border: none;
        outline: none;
        box-shadow: 0.25vw 0.25vw 0.5vw 0vw #00000014;
        padding: 1vw;
        width: 60vw;
        height: 7vw;
        border-radius: 0.5vw;
        color: #3E2A1A;
        font-size: 3vw;
        font-weight: 500;
        font-family: 'inter';
    }

    .shareYourDetailsItemDiv>input::placeholder {
        color: #3E2A1A;
    }

    .shareYourDetailsSelectDiv {
        background-color: white;
        border: none;
        outline: none;
        box-shadow: 0.25vw 0.25vw 0.5vw 0vw #00000014;
        padding: 1vw;
        width: 60vw;
        height: 7vw;
        border-radius: 0.5vw;
        color: #3E2A1A;
        font-size: 3vw;
        font-weight: 500;
        font-family: 'inter';
    }

    .shareYourDetailsSendBtn {
        width: 60vw;
        height: 7.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #422E18;
        border-radius: 5vw;
        font-size: 3.5vw;
        font-weight: 700;
        font-family: 'interBold';
        color: #fff;
        cursor: pointer;
    }

    .shareYourDetailsLastDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 4vw;
        gap: 1.5vw;
    }
    .shareYourDetailsCheckDiv{
        display:flex;
        justify-content:center;
        align-items:center;
        gap:1vw;
        padding-right:1.25vw
    }
    .shareYourDetailsCheckDiv>input{
        width:3.5vw;
        height:3.5vw;
    }
    .shareYourDetailsCheckDiv>span{
     color:#3E2A1A;
     font-family:'interSemiBold';
     font-size:3vw;
     font-weight:600;
    }
    .shareYourDetailsSocialIconDiv{
        display:flex;
        justify-content:center;
        align-items:center;
        gap:3vw;
        margin-top: 1vw;
    }
    .shareYourDetailsSocialIconDiv>img{
        width:7.5vw;
        height:7.5vw;
    }
}