.selfDriveBenefitsDiv{
    width: 52vw;
    margin: 0 auto;
    padding-top: 2.9375vw;
    margin-bottom: 5.5vw;
}
.selfDriveBenefitsItems{
    padding: 2.5vw 2.25vw;
}
.selfDriveBenefitsItems>div{
    margin-bottom: 1.8vw;
}
.selfDriveBenefitsHead{
    font-size: 1.25vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A;
}
.selfDriveBenefitsDes{
    font-size: 1.25vw;
    font-weight: 400;
    font-family: 'inter';
    color: #3E2A1A;
}
.selfDriveBenefitsMainHead{
    color:#3E2A1A;
    font-family: 'interBold';
    font-size:2.375vw;
    font-weight:800;
    text-align:center;
}

@media only screen and (max-width: 768px) {
    .selfDriveBenefitsDiv{
        width: 90vw;
        margin: 0 auto;
        padding-top: 6vw;
        margin-bottom: 6vw;
    }
    .selfDriveBenefitsItems{
        padding: 5vw 0vw;
    }
    .selfDriveBenefitsItems>div{
        margin-bottom: 5vw;
    }
    .selfDriveBenefitsHead{
        font-size: 3.8vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #3E2A1A;
    }
    .selfDriveBenefitsDes{
        font-size: 3.5vw;
        font-weight: 400;
        font-family: 'inter';
        color: #3E2A1A;
    } 
    .selfDriveBenefitsMainHead{
        color:#3E2A1A;
        font-family: 'interBold';
        font-size:6vw;
        font-weight:800;
        text-align:center;
    }
}