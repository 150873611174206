.destinationPathRow {
    width: 80vw;
    margin: 0 auto;
    padding-top: 2.125vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.destinationSocialIconDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75vw;
}

.gallerySectionMainContainer {
    width: 60vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.0625vw;
    margin-bottom: 3.625vw;
}

.gallerySectionFirstPart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25vw;
}

.gallerySectionSecondPart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.375vw;
}

.destinationDescription {
    padding-top: 3.625vw;
    width: 46.5vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.875vw;
    margin-bottom: 3.125vw;
}

.destinationPaginationDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625vw;
    font-size: 0.875vw;
    font-family: 'inter'
}

.destinationPaginationBorder {
    border-left: 0.0625vw solid #000;
    height: 1.125vw;
    background: #FAC809
}

.destinationPaginationHome {
    font-size: 1vw;
    font-weight: 400;
    font-family: 'inter';
    color: #3E2A1A;
    cursor: pointer
}

.destinationPaginationCountry {
    font-size: 1vw;
    font-weight: 700;
    font-family: 'interBold';
    color: #3E2A1A
}

.destinationSocialFbIcon {
    width: 1.375vw;
    height: 1.375vw;
}

.destinationSocialMailIcon {
    width: 1.5vw;
    height: 1.125vw;
}

.destinationSocialWhatsAppIcon {
    width: 1.375vw;
    height: 1.375vw;
}

.destinationFollowLine {
    font-size: 1.75vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A;
    text-align: center;
    padding-bottom: 1.25vw
}
.gsImg23Container{
   display:flex;
   justify-content:center;
   align-items:center;
   gap:1vw
}
.gsImg1 {
    width: 27.125vw;
    height: 31.825625vw;
}

.gsImg2 {
    width: 13.0625vw;
    height: 15.825vw;
}

.gsImg3 {
    width: 13.0625vw;
    height: 15.825vw;
}
.gsImg4{
    width:27.125vw;
    height:15.825vw;
}
.gsImg5{
    width:27.125vw;
    height:31.825625vw
}
.destinationMainCountryName{
    font-size:1.75vw;
    font-weight:800;
    font-family:'interBold';
    text-align:center;
    color:#3E2A1A
}
.destinationMainDescription{
    font-size:1vw;
    font-family:'inter';
    font-weight:400;
    color:#000000;
    text-align:center
}
.destinationRecommendLine{
    font-size:1.75vw;
    font-weight:700;
    font-family:'interBold';
    text-align:center;
    color:#3E2A1A;
    padding-top:3.125vw;
    margin-bottom:0.625vw
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: unset !important;
    height: 90vh !important;
   max-width: 90vw !important;
   min-width: 90vw !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    background-color: rgba(0, 0, 0, 0.7) !important;
}

@media only screen and (max-width: 768px) {
    .destinationPathRow {
        width: 90vw;
        margin: 0 auto;
        padding-top: 2.5vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .destinationPaginationDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5vw;
        font-size: 0.875vw;
        font-family: 'inter'
    }

    .destinationPaginationBorder {
        border-left: 0.5vw solid #000;
        height: 3.8vw;
        background: #FAC809
    }

    .destinationPaginationHome {
        font-size: 4vw;
        font-weight: 400;
        font-family: 'inter';
        color: #3E2A1A;
        cursor: pointer
    }

    .destinationPaginationCountry {
        font-size: 3.5vw;
        font-weight: 700;
        font-family: 'interBold';
        color: #3E2A1A
    }

    .destinationSocialIconDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3vw;
    }

    .destinationSocialFbIcon {
        width: 4.5vw;
        height: 4.5vw;
    }

    .destinationSocialMailIcon {
        width: 5vw;
        height: auto;
    }

    .destinationSocialWhatsAppIcon {
        width: 4.5vw;
        height: 4.5vw;
    }

    .destinationFollowLine {
        font-size: 5vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #3E2A1A;
        text-align: center;
        padding-bottom: 3vw;
        margin-top: 2vw;
    }

    .gallerySectionMainContainer {
        width: 90vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2vw;
        margin-bottom: 3.625vw;
    }

    .gallerySectionFirstPart {
        width: 50%;
        gap: 2vw;
    }

    .gallerySectionSecondPart {
        width: 50%;
        gap: 2vw;
    }
    .gsImg23Container{
        display:flex;
        justify-content:center;
        align-items:center;
        gap:2vw
     }
     .gsImg1 {
         width: 100%;
         height: auto;
     }
     
     .gsImg2 {
         width: 48%;
         height: auto;
     }
     
     .gsImg3 {
        width: 48%;
        height: auto;
     }
     .gsImg4{
         width:100%;
         height:38%;
     }
     .gsImg5{
         width:100%;
         height:58%
     }
     .destinationDescription {
        padding-top: 3.5vw;
        width: 90vw;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1vw;
        margin-bottom: 5vw;
    }
     .destinationMainCountryName{
        font-size:7vw;
        font-weight:800;
        font-family:'interBold';
        text-align:center;
        color:#3E2A1A
    }
    .destinationMainDescription{
        font-size:3.6vw;
        font-family:'inter';
        font-weight:400;
        color:#000000;
        text-align:center
    }
    .destinationRecommendLine{
        font-size:5.5vw;
        font-weight:700;
        font-family:'interBold';
        text-align:center;
        color:#3E2A1A;
        padding-top:3.125vw;
        margin-bottom:0.625vw
    }
}