.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625vw + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickableBtn, .clickableBlackBtn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.clickableBtn::after {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -1.5vw!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s 
}
.clickableBlackBtn::after {
  content: "";
  background: #000;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -1.5vw!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s 
}

.clickableBtn:active::after, .clickableBlackBtn:active::after {
  padding: 0;
  margin: 0;
  opacity: 0.5;
  transition: 0s
}
