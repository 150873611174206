.new_footer_row {
  width: 80vw;
  margin: 0 auto;
}
.new_footer_grid_box {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
  display: flex;
  gap: 6vw;
  justify-items: center;
}
.new_footer_item_box {
  line-height:  1.6vw;
}
.new_footer_item_head {
  font-size: 0.875vw;
  font-weight: 700;
  font-family: 'interBold';
}
.new_footer_item {
  font-size: 0.875vw;
  font-weight: 400;
  font-family: "inter";
  cursor: pointer;
}
.new_back_to_top {
  height: 3.5vw;
  width: 3.5vw;
  padding: -5vw;
  position: absolute;
  right: 2.375vw;
  margin-top: 18.75vw;
  cursor: pointer;
}
.footerLogoMainDiv{
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:80vw;
  margin:0 auto;
  margin-top:2.1875vw;
}
.footerLogoFirstDiv{
  display:flex;
  justify-content:center;
  align-items:center;
  gap:3.5vw
}
.footerLogoFirstLogo{
  width:5.6875vw;
  height:5.5625vw;
}
.footerLogoSecondLogo{
  width:12.875vw;
  height:5.3125vw;
}
.footerLogoFbLogo{
  width:2.1875vw;
  height:2.1875vw;
}
.mainFooterItemDiv{
  width: 100%;
  display: flex;
  justify-content:center;
  align-items:center;
  padding-top: 3.375vw;
  padding-bottom:3.375vw;
}
.footerCopyRightDiv{
  font-size:1vw;
  font-family:'inter';
  color:#000;
  text-align:center;
  margin-bottom:1.875vw;
}

@media only screen and (max-width: 768px) {
  .footerLogoMainDiv{
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:90vw;
    margin:0 auto;
    margin-top:2vw;
  }
  .footerLogoFirstLogo{
    width:15vw;
    height:15vw;
  }
  .footerLogoSecondLogo{
    width:auto;
    height:12vw;
  }
  .footerLogoFbLogo{
    width:8vw;
    height:8vw;
  }
  .mainFooterItemDiv{
    width: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    padding-top: 4vw;
    padding-bottom:4vw;
  }
  .new_footer_grid_box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4vw;
    align-items: flex-start;
    justify-content: flex-start;
  }  
  .new_footer_row {
    width: 90vw;
    margin: 0 auto;
  }
  .new_footer_item_box {
    width: 100%;
    line-height:  5.5vw;
  }
  .new_footer_item_head {
    font-size: 3.7vw;
  }
  .new_footer_item {
    font-size: 3.2vw;
  }
  .new_back_to_top {
    height: 12.5vw;
    width: 12.5vw;
    padding: -6vw;
    position: absolute;
    right: 4vw;
    margin-top: 50vw;
    cursor: pointer;
  }
  .footerCopyRightDiv{
    font-size:3vw;
    font-family:'inter';
    color:#000;
    text-align:center;
    margin-bottom:3vw;
  }
}