.privacy_table > table > tr > th {
    border: 1px solid #000 !important;
    color: #486d9b;
    text-align: center;
}
.privacy_table > table > tr > td {
    border: 1px solid #000 !important;
    vertical-align: top;
    text-align: justify;
    padding: 5px;
}