.tripPageFirstSection {
    width: 100%;
    height: 32.3125vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tripPageFirstSectionOverlay {
    background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.55) 48.58%, rgba(0, 0, 0, 0.75) 98.75%);
    height: 11.875vw;
    width: 100%;
}

.tripPageOverlayInnerMainDiv {
    width: 80vw;
    margin: 0 auto;
    padding: 2.4375vw 0vw;
    display: flex;
    /* justify-content: flex-start; */
    align-items: flex-start;
    flex-direction: column;
    gap: 1.6875vw;
}

.tripPageOverlayInnerFirstDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25vw;
}

.tripPageOverlayInnerSecondDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.625vw;
}

.tripPageRecommendedItem {
    border: 0.125vw solid #FFC432;
    border-radius: 0.1875vw;
    padding: 0.3125vw 0.625vw;
    font-size: 0.875vw;
    font-weight: 500;
    font-family: 'inter';
    color: #fff;
}

.tripPageMainDetailDiv {
    display: flex;
    gap: 3vw;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80vw;
    margin: 0 auto;
    margin-top: 2.875vw;
}

.TripDetailLeftSectionMainDiv {
    width: 75vw;
}

.tripDetailHighlightDiv {
    background: #F8F8F6;
    padding: 1.375vw 2.25vw 2.00375vw 2.25vw;
    margin-bottom: 1.6875vw;
}

.tripDetailHighlightItem {
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1vw 3vw;
}

.tripDetailHighlightItem>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.875vw;
}

.tripDetailHighlightItem>div>div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.125vw;
}

.tripDetailHighlightItem>div>div>img {
    width: 0.49625vw;
    height: 0.49625vw;
    margin-top: 0.5vw;
}

.tripDetailHighlightItem>div>div>span {
    font-size: 1vw;
    font-weight: 500;
    font-family: 'inter'
}


.TripDetailRightSectionMainDiv {
    width: 25vw;
    position: sticky;
    top: 6.875vw;
    padding-bottom: 4vw;
}

.TripDetailRightSectionFirstDiv {
    box-shadow: -0.3125vw 0.3125vw 0.625vw 0vw #00000029;
    background-color: #FBD035;
    padding: 2.1875vw 1.1875vw;
    border-radius: 0.625vw;
}

.TripDetailRightSectionDotsDiv {
    border-top: 0.625vw dotted #fff;
    position: absolute;
    z-index: 99999;
    width: 17.2vw;
    margin-top: -0.375vw;
    margin-left: 1.0625vw;
}

.TripDetailRightSectionInputContainer {
    padding-top: 1.40625vw;
    display: flex;
    flex-direction: column;
    gap: 1.23375vw;
}

.TripDetailRightSectionInputContainer>input {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 0.125vw solid #3E2A1A;
    width: 100%;
    color: #3E2A1A;
    padding: 0.5vw 0vw;
    font-size: 0.875vw;
}

.TripDetailRightSectionInputContainer>input::placeholder {
    color: #3E2A1A;
}

.TripDetailRightSectionSendBtn {
    color: #3E2A1A;
    font-size: 1.25vw;
    font-weight: 700;
    font-family: 'interBold';
    box-shadow: 0vw 0.0625vw 0.125vw 0vw #00000040;
    border-radius: 3vw;
    background-color: #FFFFFF;
    height: 2.5vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.475vw;
    cursor: pointer;
}

.TripDetailLeftDayDetail {
    width: 100%;
    background: #F8F8F6;
    border-top: 0.25vw solid #FFC432;
    padding: 1.375vw 2.25vw;
    margin-bottom: 2vw;
}

.TripDetailLeftDayDetail>div {
    font-size: 1.1vw;
    font-weight: 500;
    font-family: 'interSemiBold';
    color: #000;
    margin-left: 1.4375vw;
    margin-bottom: 1.1875vw;
}

.TripDetailLeftDayDetail>ul {
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    line-height: 180%;
    font-size: 1.1vw;
    font-weight: 400;
    font-family: 'inter';
}

.tripDetailPriceRegisterDiv {
    padding: 1.75vw 2.4375vw 1.3125vw 2.75vw;
    border-bottom: 0.25vw solid #FFC432;
    background: rgba(255, 255, 255, 0.00);
    box-shadow: 0vw 0.1875vw 0.375vw 0vw rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.75vw;
}

.tripDetailPriceRegisterDiv>ul {
    font-size: 0.875vw;
    color: #000000;
    padding: 0;
    margin: 0;
}

.tripDetailPrice {
    font-size: 2.125vw;
    font-weight: 700;
    font-family: 'interBold';
    color: #000000;
}

.tripDetailRegistrationBtn {
    box-shadow: 0vw 0.1875vw 0.375vw 0vw #00000029;
    width: 13.25vw;
    height: 2.9375vw;
    border-radius: 2.8125vw;
    background-color: #FFC432;
    font-size: 1.125vw;
    font-weight: 500;
    font-family: 'interSemiBold';
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tripDetailIncludeExcludeDiv {
    background: #F8F8F6;
    width: 100%;
    padding: 1.1875vw 4vw 2.375vw 2.25vw;
    margin-bottom: 4.0625vw;
}

.tripDetailTandCDiv {
    padding: 0.4375vw 1vw;
    width: 100%;
    border-radius: 0.125vw;
    background: #FFC432;
    box-shadow: 0vw 0.125vw 0.1875vw 0vw rgba(0, 0, 0, 0.16);
    color: #3E2A1A;
    font-family: 'interBold';
    font-size: 1.125vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625vw;
    cursor: pointer;
    margin-bottom: 6.1875vw;
}
.TcArrowIcon{
    width:0.4375vw;
    height:0.75vw;
}
.tripDetailMapDiv {
    border: 0.125vw solid #F9C60A;
    border-radius: 0.125vw;
    height: 18.5vw;
    width: 100%;
    background: #F8F8F6;
    margin-bottom: 2vw;
}

.tripPageOverlayJeepWhiteIcon {
    width: 2.5vw;
    height: 2.5vw;
}

.tripPageOverlayHead {
    font-size: 2.375vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #fff
}

.tripPageOverlayRecommendedSeason {
    font-size: 1vw;
    font-weight: 600;
    font-family: 'interSemiBold';
    color: #fff
}

.TripDetailRightSectionFirstDivHead {
    font-size: 1vw;
    font-weight: 800;
    font-family: 'interBold'
}

.TripDetailRightOfferCheckDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.6875vw;
    padding-right: 1.25vw;
    margin-top: 2vw;
    padding-left: 0.9vw;
    margin-bottom: 0.375vw;
}

.TripDetailRightOfferCheckDiv>input {
    width: 1.25vw;
    height: 1.25vw;
    cursor: pointer;
}

.TripDetailRightOfferCheckDiv>span {
    color: #3E2A1A;
    font-family: 'interSemiBold';
    font-size: 0.75vw;
    font-weight: 600
}

.TripDetailLeftSectionIntro {
    font-size: 1.125vw;
    font-weight: 500;
    font-family: 'inter';
    width: 46.875vw;
    color: #3E2A1A;
    margin-bottom: 2.75vw;
}

.tripDetailHighlightHead {
    font-size: 1.5vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A;
    margin-bottom: 1.375vw;
}

.tripDetailDayByDayHead {
    color: #3E2A1A;
    font-size: 1.5vw;
    font-weight: 800;
    font-family: 'interBold';
    margin-bottom: 1.875vw;
}

.tripDaysDBDHead {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625vw;
    font-size: 1.25vw;
    color: #3E2A1A;
    margin-bottom: 1vw;
}

.tripDaysDBDHeadBorder {
    border-left: 0.1875vw solid #3E2A1A;
    height: 1.25vw;
    background: #FAC809;
}
.tripDetailIncludeExcludeHead{
   color:#3E2A1A;
   font-size:1.125vw;
   font-weight:700;
   font-family:'interBold';
   margin-bottom:1.125vw;
}
.tripDetailIncludeExcludeList{
   font-size:1vw;
   font-weight:400;
   font-family:'inter';
   color:#3E2A1A;
   line-height:1.8vw;
   padding-left:1.4vw;
}

@media only screen and (max-width: 768px) {
    .tripPageFirstSection {
        width: 100%;
        height: 65vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .tripPageFirstSectionOverlay {
        background: linear-gradient(180.06deg, rgba(0, 0, 0, 0) -2.65%, rgba(0, 0, 0, 0.55) 48.58%, rgba(0, 0, 0, 0.75) 98.75%);
        height: 40vw;
        width: 100%;
    }

    .tripPageOverlayInnerMainDiv {
        width: 90vw;
        margin: 0 auto;
        padding: 2.4375vw 0vw;
        display: flex;
        /* justify-content: flex-start; */
        align-items: flex-start;
        flex-direction: column;
        gap: 1.6875vw;
    }

    .tripPageOverlayJeepWhiteIcon {
        width: 8vw;
        height: 8vw;
    }

    .tripPageOverlayHead {
        font-size: 5vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #fff
    }

    .tripPageOverlayInnerFirstDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 3vw;
    }

    .tripPageOverlayRecommendedSeason {
        font-size: 2.5vw;
        font-weight: 600;
        font-family: 'interSemiBold';
        color: #fff
    }

    .tripPageOverlayInnerSecondDiv {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1.5vw;
    }

    .tripPageRecommendedItem {
        border: 0.2vw solid #FFC432;
        border-radius: 0.5vw;
        padding: 0.5vw 0.8vw;
        font-size: 2.2vw;
        font-weight: 500;
        font-family: 'inter';
        color: #fff;
    }

    .tripPageMainDetailDiv {
        display: flex;
        gap: 0vw;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column-reverse;
        width: 90vw;
        margin: 0 auto;
        margin-top: 2.875vw;
    }

    .TripDetailLeftSectionMainDiv {
        width: 100%;
    }

    .TripDetailRightSectionMainDiv {
        width: 70%;
        margin: 0 auto;
        position: inherit;
        top: 0vw;
        padding-bottom: 4vw;
    }

    .TripDetailRightSectionFirstDiv {
        box-shadow: -0.3125vw 0.3125vw 0.625vw 0vw #00000029;
        background-color: #FBD035;
        padding: 3vw;
        border-radius: 2vw;
    }

    .TripDetailRightSectionFirstDivHead {
        font-size: 3.8vw;
        font-weight: 800;
        font-family: 'interBold'
    }

    .TripDetailRightSectionDotsDiv {
        border-top: 2.0vw dotted #fff;
        position: absolute;
        z-index: 99999;
        width: 58%;
        margin-top: -1vw;
        margin-left: 2.5vw;
    }

    .TripDetailRightSectionInputContainer {
        padding-top: 5vw;
        display: flex;
        flex-direction: column;
        gap: 3.5vw;
    }

    .TripDetailRightSectionInputContainer>input {
        background-color: transparent;
        outline: none;
        border: none;
        border-bottom: 0.3vw solid #3E2A1A;
        width: 100%;
        color: #3E2A1A;
        padding: 0.8vw 0.5vw;
        font-size: 3vw;
    }

    .TripDetailRightSectionSendBtn {
        color: #3E2A1A;
        font-size: 3.5vw;
        font-weight: 700;
        font-family: 'interBold';
        box-shadow: 0vw 0.0625vw 0.125vw 0vw #00000040;
        border-radius: 7vw;
        background-color: #FFFFFF;
        height: 7vw;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5vw;
        cursor: pointer;
    }

    .TripDetailRightOfferCheckDiv {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2vw;
        padding-right: 1.25vw;
        margin-top: 3vw;
        padding-left: 0.9vw;
        margin-bottom: 3vw;
    }

    .TripDetailRightOfferCheckDiv>input {
        width: 3vw;
        height: 3vw;
        cursor: pointer;
    }

    .TripDetailRightOfferCheckDiv>span {
        color: #3E2A1A;
        font-family: 'interSemiBold';
        font-size: 2.8vw;
        font-weight: 600
    }

    .TripDetailLeftSectionIntro {
        font-size: 3.5vw;
        font-weight: 500;
        font-family: 'inter';
        width: 100%;
        color: #3E2A1A;
        margin-bottom: 5vw;
    }

    .tripDetailHighlightDiv {
        background: #F8F8F6;
        padding: 4vw;
        margin-bottom: 5vw;
    }

    .tripDetailHighlightHead {
        font-size: 3.8vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #3E2A1A;
        margin-bottom: 2vw;
    }

    .tripDetailHighlightItem {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 2vw;
    }

    .tripDetailHighlightItem>div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2vw;
    }

    .tripDetailHighlightItem>div>div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2vw;
    }

    .tripDetailHighlightItem>div>div>img {
        width: 1.8vw;
        height: 1.8vw;
        margin-top: 1.2vw;
    }

    .tripDetailHighlightItem>div>div>span {
        font-size: 2.6vw;
        font-weight: 500;
        font-family: 'inter'
    }

    .tripDetailMapDiv {
        border: 0.5vw solid #F9C60A;
        border-radius: 0.5vw;
        height: 60vw;
        width: 100%;
        background: #F8F8F6;
        margin-bottom: 5vw;
    }

    .tripDetailDayByDayHead {
        color: #3E2A1A;
        font-size: 4.5vw;
        font-weight: 800;
        font-family: 'interBold';
        margin-bottom: 2.5vw;
    }

    .tripDaysDBDHead {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5vw;
        font-size: 2.8vw;
        color: #3E2A1A;
        margin-bottom: 1vw;
    }

    .tripDaysDBDHeadBorder {
        border-left: 0.5vw solid #3E2A1A;
        height: 2.8vw;
        background: #FAC809;
    }

    .TripDetailLeftDayDetail {
        width: 100%;
        background: #F8F8F6;
        border-top: 0.5vw solid #FFC432;
        padding: 2.5vw 3.5vw;
        margin-bottom: 3vw;
    }

    .TripDetailLeftDayDetail>div {
        font-size: 2.5vw;
        font-weight: 500;
        font-family: 'interSemiBold';
        color: #000;
        margin-left: 1.4375vw;
        margin-bottom: 1.1875vw;
    }

    .TripDetailLeftDayDetail>ul {
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        line-height: 180%;
        font-size: 2.5vw;
        font-weight: 400;
        font-family: 'inter';
    }

    .tripDetailPriceRegisterDiv {
        padding: 5vw 3vw;
        border-bottom: 0.5vw solid #FFC432;
        background: rgba(255, 255, 255, 0.00);
        box-shadow: 0vw 0.1875vw 0.375vw 0vw rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        gap: 1.8vw;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .tripDetailPriceRegisterDiv>ul {
        font-size: 2.2vw;
        color: #000000;
        padding: 0vw 3vw;
        margin: 0vw;
        line-height: 190%;
    }

    .tripDetailPrice {
        font-size: 5vw;
        font-weight: 700;
        font-family: 'interBold';
        color: #000000;
    }

    .tripDetailRegistrationBtn {
        box-shadow: 0vw 0.1875vw 0.375vw 0vw #00000029;
        width: 40vw;
        height: 9vw;
        border-radius: 10vw;
        background-color: #FFC432;
        font-size: 3.5vw;
        font-weight: 500;
        font-family: 'interSemiBold';
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .tripDetailIncludeExcludeDiv {
        background: #F8F8F6;
        width: 100%;
        padding: 4vw;
        margin-bottom: 5vw;
    }
    .tripDetailIncludeExcludeHead{
        color:#3E2A1A;
        font-size:3vw;
        font-weight:700;
        font-family:'interBold';
        margin-bottom:2vw;
     }
     .tripDetailIncludeExcludeList{
        font-size:2.5vw;
        font-weight:400;
        font-family:'inter';
        color:#3E2A1A;
        line-height:4.5vw;
        padding-left:2.5vw;
     }
     .tripDetailTandCDiv {
        padding: 1.5vw;
        width: 100%;
        border-radius: 0.5vw;
        background: #FFC432;
        box-shadow: 0vw 0.125vw 0.1875vw 0vw rgba(0, 0, 0, 0.16);
        color: #3E2A1A;
        font-family: 'interBold';
        font-size: 2.5vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5vw;
        cursor: pointer;
        margin-bottom: 6vw;
    }
    .TcArrowIcon{
        width:1.5vw;
        height:2.5vw;
    }    
}