.registerMainContainer{
    background-image: url('../assets/loginMainBg.png');
    width: 100%;
    height: 100vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
    z-index: 1;
}
.registerInnerContainer{
    width: 30vw;
    margin: 0 auto;
    padding: 2vw 1.25vw;
}
.registerInputCss{
    padding: 0.4375vw 0.6875vw;
    font-size: 1vw;
    line-height: 1.5;
    border-radius: 0.5vw;
    color: rgba(0, 0, 0, 0.88);
    background-color: #ffffff;
    border-width: 0.0625vw;
    border-style: solid;
    border-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 1.875vw;
}
.registerInputCss:hover{
    border-color: #4096ff;
}
.registerInputCss > input{
    width: 100%;
    outline: none;
    border: unset;
}
/* .registerPageBtn{
    font-size: 1vw;
    height:  2.5vw;
    padding: 0.40625vw 0.9375vw;
    border-radius: 0.5vw;
    color: #fff;
    background: #1677ff;
    box-shadow: 0 0.125vw 0 rgba(5, 145, 255, 0.1);
    cursor: pointer;
    width: 100%;
    margin-top: 0.625vw;
    border: unset;
}
.registerPageBtn:hover{
    color: #fff;
    background: #4096ff;
} */
.inputErrorMsg {
    font-size: 0.8125vw;
    color: #ff3180;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 0.625vw;
    margin-top: -1.25vw;
    margin-bottom: 0.625vw;
  }
  
  .backArrowiconDiv{
    background-color: #fff;
    box-shadow: 0.1875vw 0.1875vw 0.625vw rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3125vw;
    padding: 0.5vw 0.9375vw;
    font-size: 1vw;
    color: #000;
    cursor: pointer;
    width: max-content;
    border-radius: 0.5vw;
    position: absolute;
    top: 1.875vw;
    left: 1.875vw;
  }
  .backArrowiconDiv>img{
   width: 1.25vw;
  }

  


  .registerFormDiv{
    width: 50vw;
    margin: 3.75vw auto;
    padding: 1.25vw 6.25vw;
}
.registerInputCss{
    padding: 0.4375vw 0.6875vw;
    font-size: 1vw;
    line-height: 1.5;
    border-radius: 0.5vw;
    color: rgba(0, 0, 0, 0.88);
    background-color: #ffffff;
    border-width: 0.0625vw;
    border-style: solid;
    border-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
    margin-bottom: 2.2vw;
}
.registerInputCss:hover{
    border-color: #4096ff;
}
.registerInputCss > input{
    width: 100%;
    outline: none;
    border: unset;
}
.dropDownDiv{
    font-size: 1vw;
    width: 37.5vw;
    max-height: 12.5vw;
    border: 0.0625vw solid grey;
    border-radius: 0.25vw;
    padding: 0.25vw;
    position: absolute;
    background-color: #fff;
    overflow-y: scroll;
    z-index: 1;
    margin-top: -2vw;
    /* margin-left: -0.7vw; */
}
.countryDropDownItemDiv{
    padding: 0.3125vw 0.5vw;
    cursor: pointer;
}
.countryDropDownItemDiv:hover{
    background-color: rgba(236, 240, 241);
}
/* .registerPageBtnUpperDiv{
    background-color: #fac809;
    padding: 0.125vw;
    border-radius: 0.5vw;
} */
.registerPageBtn{
    font-size: 1.2vw;
    height:  3vw;
    padding: 0.40625vw 0.9375vw;
    border-radius: 0.5vw;
    color: #3e2a1a;
    font-weight: bold;
    background: #fac809;
    box-shadow: 0 0.125vw 0 rgba(5, 145, 255, 0.1);
    cursor: pointer;
    width: 100%;
    border: unset;
    border: 0.125vw solid #3e2a1a;
    margin-top: 1.4vw;
}
.registerPageBtn:hover{
    opacity: 0.75;
}
@media only screen and (max-width: 768px) {
    .registerFormDiv{
        width: 90vw;
        margin: 8vw auto;
        padding: 0vw;
    }
    .registerInputCss {
        padding: 1.5vw 1vw;
        font-size: 3.5vw;
        line-height: 1.5;
        border-radius: 0.5vw;
        color: rgba(0, 0, 0, 0.88);
        background-color: #ffffff;
        border-width: 0.5vw;
        border-style: solid;
        border-color: #d9d9d9;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5vw;
        margin-bottom: 5vw;
    }
    .dropDownDiv {
        font-size: 3.5vw;
        width: 90vw;
        max-height: 30vw;
        border: 0.0625vw solid grey;
        border-radius: 0.25vw;
        padding: 0.25vw;
        position: absolute;
        background-color: #fff;
        overflow-y: scroll;
        z-index: 0;
        margin-top: -2vw;
        /* margin-left: -0.7vw; */
    }
    .registerPageBtn {
        font-size: 4vw;
        height: 10vw;
        padding: 0.40625vw 0.9375vw;
        border-radius: 0.5vw;
        color: #3e2a1a;
        font-weight: bold;
        background: #fac809;
        box-shadow: 0 0.125vw 0 rgba(5, 145, 255, 0.1);
        cursor: pointer;
        width: 100%;
        border: unset;
        border: 0.125vw solid #3e2a1a;
        margin-top: 1.4vw;
    }
}