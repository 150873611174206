body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  font-family: "poppins";
}

@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./Poppins/Poppins-Regular.ttf) format("truetype");
  /*  src: local('poppins'), url(./Poppins/Poppins-Regular.ttf) format('truetype'); */
  /* src: local('poppins'), url(./Poppins/Poppins-Medium.ttf) format('truetype'); */
}
@font-face {
  font-family: "poppinsSemiBold";
  src: local("poppinsSemiBold"),
    url(./Poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "poppinsBold";
  src: local("poppinsBold"), url(./Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "inter";
  src: local("inter"), url(./Inter/static/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "interSemiBold";
  src: local("InterSemiBold"),
    url(./Inter/static/Inter-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "interBold";
  src: local("InterBold"),
    url(./Inter/static/Inter-Bold.ttf) format("truetype");
}